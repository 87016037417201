'use client';

import { useContext } from 'react';
import { AppConfigContext } from '../contexts';
import { AppConfig } from '../types';

export function useAppConfig<ThrowCheck extends boolean = true>(
    throwIfMissing?: ThrowCheck,
): ThrowCheck extends true ? AppConfig : AppConfig | null {
    const throwIfMissingOrDefault = throwIfMissing ?? true;
    const config = useContext(AppConfigContext);
    if (config === null && throwIfMissingOrDefault) {
        throw new Error('The config does not exist yet');
    }
    return config as ThrowCheck extends true ? AppConfig : AppConfig | null;
}
