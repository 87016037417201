'use client';

import { ExternalFrontendRouter } from '@deltasierra/frontend/routing/core';
import { AppWebRouterType, AvailableRoutes } from '@deltasierra/frontend/routing/app-web';
import { useMemo } from 'react';
import { useAppConfig } from './useAppConfig';

export const useAppWebRouter = (): AppWebRouterType => {
    const config = useAppConfig();
    return useMemo(() => new ExternalFrontendRouter<AvailableRoutes>(config.appWebUrl), [config]);
};
