'use client';

import { ErrorPage, ErrorPageProps } from '@deltasierra/react/components/error-page';
import { useTranslations } from '@deltasierra/translations/react';
import { useAppWebRouter } from '../hooks/useAppWebRouter';

export type ErrorPageContainerSafeProps = Omit<
    ErrorPageProps,
    'errorCode' | 'errorMessage' | 'homeHref' | 'logoSrc' | 't'
> & {
    appWebRouter: ReturnType<typeof useAppWebRouter> | null;
    error: unknown;
};

export function ErrorPageContainerSafe({ appWebRouter, error, ...props }: ErrorPageContainerSafeProps): JSX.Element {
    /**
     * TODO: We should render a different view here, one that doesn't use the router.
     * This can occur if the error boundary is hit at the NextJS global error page and we
     * don't have any config in the context.
     *
     * We probably have a requirement on translations though so the translation hook is fine
     */
    const t = useTranslations('ErrorPage');

    const errorCode = typeof error === 'object' ? (error as Error & { digest?: string }).digest : undefined;
    const errorMessage =
        typeof error === 'object' && error !== null && 'message' in error && typeof error.message === 'string'
            ? error.message
            : undefined;
    return (
        <ErrorPage
            {...props}
            errorCode={errorCode}
            errorMessage={errorMessage}
            homeHref={appWebRouter?.get('/') ?? '/'}
            logoSrc="/ds_icon.svg"
            t={t}
        />
    );
}
ErrorPageContainerSafe.displayName = 'ErrorPageContainerSafe';
