import { Translations } from '@deltasierra/translations/react';
import { Box, Button, Container, Stack, Typography } from '@deltasierra/react/components/core';
import Image from 'next/image';

export type ErrorPageProps = {
    errorCode?: string;
    errorMessage?: string;
    homeHref: string;
    logoSrc: string;
    reset?: () => void;
    t: Translations<'ErrorPage'>;
};

export const ErrorPage = ({ errorCode, errorMessage, homeHref, logoSrc, reset, t }: ErrorPageProps): JSX.Element => (
    <Container
        sx={{
            display: 'flex',
            minHeight: '100svh',
            minWidth: '356px',
            overflow: 'auto',
        }}
    >
        <Box
            sx={{
                display: 'flex',
                paddingBottom: 3,
                paddingLeft: 3,
                paddingRight: 3,
                paddingTop: 3,
                width: '100%',
            }}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    background: 'white',
                    borderRadius: 3,
                    display: 'flex',
                    paddingBottom: 4,
                    paddingLeft: 3,
                    paddingRight: 3,
                    paddingTop: 4,
                    width: '100%',
                }}
            >
                <Stack
                    spacing={4}
                    sx={{
                        alignItems: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Stack
                        spacing={4}
                        sx={{
                            alignItems: 'center',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            maxWidth: '260px',
                            textAlign: 'center',
                        }}
                    >
                        <Image alt="Digital Stack" height={148} src={logoSrc} width={235} />
                        <Typography variant="h1">
                            {t(errorMessage ? 'Whoops, something went wrong!' : 'Page not found')}
                        </Typography>
                        {!errorMessage && (
                            <Typography component="h2" variant="h4">
                                {t("That page can't be reached, but if you double-check it, we're happy to try again")}
                            </Typography>
                        )}
                        {errorCode && (
                            <Typography color="error.dark" variant="body1">{`Error code: ${errorCode}`}</Typography>
                        )}
                    </Stack>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
                        <Button color={reset ? 'secondary' : 'primary'} href={homeHref} variant="contained">
                            {t('Back to homepage')}
                        </Button>
                        {reset && (
                            <Button color="primary" variant="contained" onClick={() => reset()}>
                                {t('Try again')}
                            </Button>
                        )}
                    </Box>
                </Stack>
            </Box>
        </Box>
    </Container>
);

ErrorPage.displayName = 'ErrorPage';
