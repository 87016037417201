'use client';

import { ErrorPageContainer } from '../../components/ErrorPageContainer';

/**
 * This is the App error handler.
 *
 * This captures errors on most pages nested under the main app layout. It's useful
 * to have this page separate from the Root error handler because this will have
 * all the providers from the App layout as well.
 */
export default function AppError({
    error,
    reset,
}: {
    error: unknown;
    reset: () => void;
}): JSX.Element {
    return <ErrorPageContainer error={error} reset={reset} />;
}
AppError.displayName = 'AppError';
