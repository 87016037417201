'use client';

import { RedirectError } from '@deltasierra/frontend/errors';
import { AvailableRoutes } from '@deltasierra/frontend/routing/app-web';
import { useRouter } from 'next/navigation';
import { useAppWebRouter } from '../hooks/useAppWebRouter';
import { ErrorPageContainerSafe, ErrorPageContainerSafeProps } from './ErrorPageContainerSafe';

/**
 * This is the main error page container.
 * It should be used from any route that needs to customize the props of the ErrorPage component.
 *
 * This one has a dependency on the app config and app router, so we only can't use it at the global
 * error boundary.
 */
export function ErrorPageContainer({
    error,
    ...props
}: Omit<ErrorPageContainerSafeProps, 'appWebRouter'>): JSX.Element {
    const appWebRouter = useAppWebRouter();
    const router = useRouter();

    if (error instanceof RedirectError) {
        const url = error.url ?? appWebRouter.get('/');

        if (error.options.isNewApp) {
            router.replace(url);
        } else if (error.options.isUseAppWebRouter) {
            window.location.replace(appWebRouter.get(url as AvailableRoutes));
        } else {
            window.location.replace(url);
        }
        return <></>;
    }

    return <ErrorPageContainerSafe {...props} appWebRouter={appWebRouter} error={error} />;
}
ErrorPageContainer.displayName = 'ErrorPageContainer';
