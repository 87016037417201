import { AvailableRoutes } from '@deltasierra/frontend/routing/app-web';
import { HandledError } from './handled-error';

export class RedirectError extends HandledError {
    public options: {
        isNewApp: boolean;
        isUseAppWebRouter: boolean;
    };

    public constructor(
        public readonly url: AvailableRoutes | string | null,
        options?: {
            isNewApp?: boolean;
            isUseAppWebRouter?: boolean;
        },
    ) {
        super(`Redirecting to ${url}`);
        this.options = {
            isNewApp: options?.isNewApp ?? false,
            isUseAppWebRouter: options?.isUseAppWebRouter ?? false,
        };
    }
}
